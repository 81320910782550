
questionnaireApp = angular.module("questionnaireApp.filters", [])

questionnaireApp.filter 'range', ->
    (input, total) ->
        total = parseInt(total)
        i = 1
        while i <= total
            input.push i
            i++
        input

questionnaireApp.filter 'reverse', ->
    (items) ->
        copy = [].concat(items);
        copy.slice().reverse()
